@charset "utf-8";

// Disable animations
// https://mmistakes.github.io/minimal-mistakes/docs/stylesheets/#disabling-animations
$intro-transition  : none;
$global-transition : none;

// 既定より倍率を抑えめにしている
$doc-font-size: 16px !default;
$doc-font-size-medium: $doc-font-size * 1.1 !default;
$doc-font-size-large: $doc-font-size * 1.2 !default;
$doc-font-size-x-large: $doc-font-size * 1.3 !default;

// 行あたりの文字数を抑えめにするために、諸々の数値を小さめに調整している
$small: 600px !default;
$medium: 700px !default;
$medium-wide: 800px !default;
$large: 900px !default;
$x-large: 1000px !default;
$max-width: 1200px !default;

// Inter と BIZ UD ゴシックを利用する
$sans-serif : "Inter", "BIZ UDGothic", -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", Arial, sans-serif;

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials


html {
  /* apply a natural box layout model to all elements */
  box-sizing: border-box;
  background-color: $background-color;
  font-size: $doc-font-size;

  @include breakpoint($medium) {
    font-size: $doc-font-size-medium;
  }

  @include breakpoint($large) {
    font-size: $doc-font-size-large;
  }

  @include breakpoint($x-large) {
    font-size: $doc-font-size-x-large;
  }

  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

div.page__inner-wrap {
  // 既定より行間隔を広げている
  line-height: 2em;
}

table {
  // 既定より大きくしている
  font-size: 0.9em;
}
